<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>FAQ</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">FAQ</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Choose CodeUp</span>
            <h2>We Achieved People’s Trust by Our Great Service</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img.png" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <accordion>
                            <accordion-group [isOpened]="true" heading="What services does CodeUp provide?">
                                <p>CodeUp provides services such as Visual Design, Application Development and IT
                                    Management. We also provide advanced security and scalability.
                                </p>
                            </accordion-group>
                            <accordion-group heading="Does CodeUp offer 24/7 support?">
                                <p>Yes, we offer 24/7 customer support to ensure that our customers are able to get the
                                    help they need when they need it.</p>
                            </accordion-group>
                            <accordion-group heading="Does CodeUp provide training?">
                                <p>Yes, we provide comprehensive training programs to help our customers maximize the
                                    potential of their IT solutions.</p>
                            </accordion-group>
                            <accordion-group heading="Is CodeUp committed to innovation?">
                                <p>Yes, we are committed to staying ahead of the technology curve and providing our
                                    customers with the latest solutions available.</p>
                            </accordion-group>
                        </accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-contact-area home-2-contact pb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Let Us Know About Your Project Idea!</h2>
        </div>

        <div class="content">
            <form id="contactForm">
                <div class="row">
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="name" id="name" class="form-control" required
                                placeholder="Your Name" />
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="email" name="email" id="email" class="form-control" required
                                placeholder="Your Email" />
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" required class="form-control"
                                placeholder="Your Phone" />
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" required
                                placeholder="Subject" />
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                            <textarea name="message" class="form-control" id="message" cols="30" rows="5" required
                                placeholder="Your Message"></textarea>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>