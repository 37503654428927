<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="content">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/images/logo2.png" alt="logo" /></a>
                    </div>
                    <br>

                    <div class="subscribe">
                        <h4>Join Newsletter</h4>
                        <form class="newsletter-form">
                            <input type="email" id="emails" class="form-control" placeholder="Your Email" name="EMAIL"
                                required autocomplete="off">
                            <button class="box-btn" type="submit">Subscribe</button>
                        </form>
                    </div>

                    <ul class="social">
                        <li><a href="https://www.linkedin.com/company/codeupsoftware/" target="_blank"><i
                                    class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="content">
                    <h3>Quick Links</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/faq">FAQ</a></li>
                        <li><a routerLink="/solutions">Service</a></li>
                        <li><a routerLink="/privacy-policy">Privacy & Policy</a></li>
                        <li><a routerLink="/terms-condition">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <div class="content contacts">
                    <h3 class="ml-40">Contact</h3>

                    <ul class="footer-list foot-social">
                        <li><a href="mailto:contact@codeup.click"><i class="bx bxs-envelope"></i>
                                contact@codeup.click</a></li>
                        <li><i class="bx bxs-map"></i> 2121 SW 3rd Ave, Miami, FL 33129, USA
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copy-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <ul class="menu">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about">About</a></li>
                        <li><a routerLink="/solutions">Solutions</a></li>
                        <li><a routerLink="/faq">FAQ</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>

                <div class="col-lg-6">
                    <p>Copyright © 2023 CodeUp</p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>