<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">About</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Our Company</span>
                        <h2>Innovative It Helping Service Allover the World</h2>
                        <p>CodeUp is a leading IT solutions provider that specializes in providing services such as
                            Visual Design, Application Development and IT Management. Our team of experienced
                            professionals is dedicated to providing the highest level of service to our customers. We
                            strive to exceed
                            customer expectations by offering superior customer service and technical support. We also
                            provide advanced security and scalability to help customers optimize
                            their IT solutions. Our commitment to innovation ensures that we are always at the forefront
                            of the IT industry.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img1.jpg" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Updated Technology</h2>
                    <p>We stay up-to-date with the latest technology and practices to ensure that our customers are able
                        to maximize the potential of their IT solutions. We are constantly innovating and updating our
                        technology to ensure that our customers have access to the most advanced solutions available.
                        Our commitment to innovation ensures that our customers have access to the most cutting-edge
                        technology. Additionally, our experienced professionals are always available to offer customer
                        service and technical support.</p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feature-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <span>Features</span>
                        <h2>We Have Also Some Features That Provided by CodeUp</h2>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> Freelancing Traning Course</li>
                        <li><i class="flaticon-correct"></i> Technological Consultation</li>
                        <li><i class="flaticon-correct"></i> Monthly Paid Workspace</li>
                        <li><i class="flaticon-correct"></i> IT Learning Institute</li>
                        <li><i class="flaticon-correct"></i> Digital Marketing</li>
                    </ul>
                    <a routerLink="/contact" class="box-btn">Lets Talk!</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>