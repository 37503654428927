<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Solutions</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Solutions</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-service-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Smart Services</span>
            <h2>CodeUp Provide All Kind of Tech Solutions</h2>
            <p> CodeUp is a technology company that provides a variety of services for businesses. They specialize in
                Visual Design, Development, QA testing, IT management, Cyber Security and Wireless
                Connectivity. They strive to give their clients the best possible solutions to their technology needs.
            </p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Visual Design</h3>
                        <p>
                            Visual design is about creating an aesthetically pleasing, intuitive and engaging experience
                            for users.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Development</h3>
                        <p>We develop software solutions to help businesses improve their operations and create better
                            customer experiences.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>QA Testing</h3>
                        <p>Verifying accuracy of data, validating requirements, debugging, and monitoring for any
                            unexpected behavior.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>IT Management</h3>
                        <p>Planning, deploying, configuring, monitoring, maintaining and securing IT systems and
                            services.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> Cyber Security</h3>
                        <p>Protecting networks, computers and data from unauthorized access, attacks and exploits.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> Wireless Connectivity</h3>
                        <p>We provide secure, fast and reliable wireless solutions for connecting devices to the
                            internet.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>